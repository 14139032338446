@use "../mixins";
@use "../screens";
@use "../typography";
@use "../variables";


.header {
  @include screens.laptop {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem;
  }
  .title {
    @include typography.heading1;
    margin: 10px;
  }
  .subtitle {
    @include typography.heading2;
    color : variables.$pink;
    margin: 10px;

    @include screens.laptop {
      width: 75%;
    }
  }
  .image {
    display: none;
    width: 50%;
    height: auto;
    border-radius: 10px;

    @include screens.laptop {
      display: block;
    }
  }
}

.section1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  @include screens.laptop {
    flex-direction: row;
    padding: 3rem;
    justify-content: center;
  }

  @include screens.laptop {
    .image {
      width: 50%;
      border-radius: 10px;
    }
  }
  .texts {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    @include screens.laptop {
      width: 50%;
      padding: 50px;
    }
    .text {
      margin: 10px;
      @include typography.paragraph;
      @include screens.laptop {
        margin: 20px;
      }
    }   
    .button {
      @include mixins.button;
      align-self: center;
    }

    .pink {
      color: variables.$pink;
      font-weight: bold;
    }
  }   
}

.section2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include screens.laptop {
    display: flex;
    flex-direction: row;
  }   
  .article {
    display: flex;
    flex-direction: column;
    margin: 20px;
    margin-top: 0px;
    @include screens.laptop {
      width: 50%;
      padding: 50px;
    }
    .title {
      color : variables.$pink;
      @include typography.heading2;
      margin: 10px 10px 10px 0px;
    }
    .subtitle{
      @include typography.heading3;
      margin-bottom: 10px;
      @include screens.laptop {
        margin: 20px;
      }
    }

    .text {
      margin-bottom: 10px;
      @include typography.paragraph;
      @include screens.laptop {
        margin: 20px;
      }
    }  
    .button {
      @include mixins.button(false);
      margin-bottom: 20px;
      align-self: center;
    }    
  }
  @include screens.laptop {
    .image {
      width: 50%;
      border-radius: 10px;
    }
  }
}
  


