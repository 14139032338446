@mixin mobile {
  @media (max-width: 480px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 480px) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1600px) {
    @content;
  }
}
