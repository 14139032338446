@use "../mixins";
@use "../screens";
@use "../typography";
@use "../variables";

.header {
  @include screens.laptop {
    display: flex;
    align-items: center;
    padding: 3rem;
  }

  .title {
    @include typography.heading1;

    margin: 10px;

    .pink {
      color : variables.$pink;

      margin: 10px;

      @include screens.laptop {
        width: 75%;
      }
    }
  }
}
