@use "../mixins";
@use "../screens";
@use "../typography";
@use "../variables";

.titre {
  @include typography.heading1;

  margin-bottom: 30px;

  @include screens.mobile {
    font-size: 2.5rem;
    margin-left: 20px;
  }
}

.studiosContainer {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @include screens.laptop {
    flex-direction: row;
  }

  .studio {
    flex: 0 1 100%;

    .title {
      @include typography.heading2;

      margin: 0;
    }

    .list {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-top: 1.5rem;

      .name {
        padding: 1em 1.5em;
        border-radius: 0.5rem;
        border: 2px solid variables.$light-grey;
        background-color: white;
        transition: background-color 0.2s ease-in-out;

        &:hover {
          background-color: variables.$light-grey;
        }
      }
    }
  }
}

.button {
  @include mixins.button(true, true);

  margin: 2rem auto 0;
}

.popup {
  @include mixins.popup(true);

  .form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .label {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .input {
        @include mixins.back-input;
      }
    }

    .button {
      @include mixins.button(true, true);

      margin: 1rem auto 0;
    }

    .wrapperButton {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      align-items: center;
      justify-content: center;

      @include screens.laptop {
        flex-direction: row;
      }
    }

    .smallButton {
      @include mixins.button(true, true, #ff2828);

      padding: 1em 1.5em;
    }
  }
}
