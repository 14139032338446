@use "../../../styles/mixins";
@use "../../../styles/screens";
@use "../../../styles/typography";
@use "../../../styles/variables";

.studios {
  display: flex;
  flex-direction: column;

  @include screens.laptop {
    flex-direction: row;
    margin-top: 5rem;
  }

  .item {
    flex: 1 1 100%;

    .figure {
      height: 16rem;
      position: relative;

      @include screens.laptop {
        height: 100vh;
      }

      .image {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }

      .gradient {
        position: absolute;
        inset: 0;
        background: linear-gradient(to right, transparent, rgba(variables.$dark, 0.8));

        @include screens.laptop {
          background: linear-gradient(transparent, rgba(variables.$dark, 0.8));
        }
      }

      .content {
        position: absolute;
        inset: 0 1.5rem 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: end;

        @include screens.laptop {
          inset: 0;
          align-items: center;
          justify-content: center;
        }

        .logo {
          width: 8rem;

          @include screens.laptop {
            width: min(50%, 20rem);
            position: absolute;
            bottom: 0;
          }
        }

        .button {
          @include mixins.button;
        }
      }
    }

    @include screens.mobile {
      &:nth-of-type(2) {
        .gradient {
          transform: rotate(180deg);
        }

        .content {
          inset: 0 auto 0 1.5rem;
          align-items: start;
        }
      }
    }
  }
}
