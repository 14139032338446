@use "../mixins";
@use "../screens";
@use "../typography";
@use "../variables";

.wrapper {
  @include mixins.section;
}

.hero {
  @include typography.heading1;

  .pink {
    color: variables.$pink;
  }
}

.figure {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2rem;

  @include screens.laptop {
    flex-direction: row;
    gap: 4rem;
  }

  .figcaption {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .title {
      @include typography.heading2;
    }

    .paragraph {
      margin-top: 1rem;
    }

    .link:hover {
      text-decoration: underline;
    }
  }

  .map {
    border: none;
    border-radius: 0.5rem;
    width: 100%;
    min-height: 20rem;
  }
}
