@use "../mixins";
@use "../screens";
@use "../typography";
@use "../variables";

.header {
    @include screens.laptop {
      display: flex;
      align-items: center;
      padding: 3rem;
    }
  
    .title {
      @include typography.heading1;
      margin: 10px;
      margin-bottom: 30px;
    }

    .text {
      @include typography.paragraph;
      margin-bottom: 20px;
      font-size: 1.2rem;
      @include screens.mobile {
        margin: 10px;
      }
    }
    .pink {
      color : variables.$pink;
    }
  }
