@use "../../../styles/mixins";
@use "../../../styles/screens";
@use "../../../styles/typography";
@use "../../../styles/variables";
@use "sass:color";

.planningItem {
  display: flex;
  background-color: variables.$dark;
  border-radius: 0.5rem;
  text-align: left;
  align-items: stretch;
  width: 100%;
  transition: transform 0.2s ease-in-out;

  &:hover {
    transform: translateY(-0.25rem);
  }

  .aside {
    padding: 0.75rem 0.25rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: variables.$dark;

    &:global(.Wellness) {
      background-color: variables.$wellness;
    }

    &:global(.Biking) {
      background-color: variables.$biking;
    }

    &:global(.Cross-training) {
      background-color: variables.$cross;
    }

    .top,
    .bottom {
      @include typography.paragraph-bold;
    }

    .line {
      display: block;
      height: 100%;
      width: 2px;
      background-color: variables.$dark;
    }
  }

  &:not(&.light).disabled {
    color: rgba(white, 0.5);

    .aside {
      background-color: color.adjust(variables.$dark, $lightness: 20%, $saturation: -10%);
    }
  }

  .main {
    padding: 0.75rem;

    .title {
      font-weight: 500;
      font-size: 1.1rem;
    }

    .coach {
      margin: 0.25rem 0 2rem;
    }
  }

  &.light {
    background-color: variables.$light-grey;

    // .aside.disabled {
    //   background-color: color.adjust(variables.$light-grey, $blackness: 10%);
    // }
  }
}
