@use "../mixins";
@use "../screens";
@use "../typography";
@use "../variables";

.titre {
    @include typography.heading1;
    margin-bottom: 30px;
    @include screens.mobile {
        font-size: 2.5rem;
        margin-left: 20px;
    }
}

.searchContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1.25em;
  margin: 0 2rem 2rem;
  border-radius: 0.5rem;
  width: min(calc(100% - 4rem), 32rem);
  background-color: rgba(variables.$light-grey, 0.5);

  @include screens.laptop {
    margin: 0 0 2rem;
  }

  .input {
    width: 100%;
  }
}

.tableau {
    padding-bottom: 5rem;
    @include screens.mobile {
        width: 100%;
        padding: 0px 15px 3rem 15px;
    }
    &__header {
        width: 100%;
        border-bottom: 1px solid black;
    }
    &__entete {
        padding-bottom: 10px;
        @include screens.mobile {
            font-size: 0.8rem;
        }
    }
    &__contenant {
        overflow: scroll;
    }
    &__contenu {
        @include screens.mobile {
            font-size: 0.8rem;
        }
        text-align: center;
        padding: 10px;

        > a {
            display:block;
            width:20px;
        }
    }
    @include screens.mobile {
        &__mobile {
            display: none;
        }
    }
    &__desktop {
        display: none;
        @include screens.mobile {
            display: block;
        }
    }
}

.icone{
    width: 20px;
    height: auto;
    @include screens.mobile {
        width: 85%;
    }
}

.aside {
    position: fixed;
    right: 0px;
    bottom: 0px;
    width: 100%;
    display: flex;
    background-color: white;
    justify-content: center;
    @include screens.mobile {
        width: 100%;
    }
    &__button {
        @include mixins.button(true);
        margin: 20px 20px 20px 20px;
        border-color: black;
        &:hover {
            color: white;
            background-color: black;
            border-color: white;
        }
    }
}

.popup {
    background-color: white;
    color: variables.$dark;
    padding: 2rem;
    border-radius: 0.5rem;
    border: variables.$border;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: min(calc(100% - 3rem), 32rem);
    position: relative;
    transform: scale(0.8) translateY(-1rem);
    transition: transform 0.1s ease-in, opacity 0.1s ease-in;

    @include screens.laptop {
        padding: 3rem;
    }

    .cross {
      filter: invert(1);
    }

    &:global(.open) {
        transform: none;
        transition: transform 0.3s cubic-bezier(0, 0.5, 0.5, 1.5), opacity 0.3s cubic-bezier(0, 0.5, 0.5, 1.5);
    }

    .cross {
        position: absolute;
        inset: 1rem 1rem auto auto;
    }

    .title {
        @include typography.heading3;

        text-align: center;
        margin: 0 auto 1rem;
    }

    .text {
        .bold {
            @include typography.paragraph-bold;
            word-wrap:break-word;
            margin-left: 1rem;
        }
    }

    .button {
        @include mixins.button(true);
        margin: 1rem auto 0;
        border-color: black;
        &:hover {
            color: white;
            background-color: black;
            border-color: white;
        }
    }

  .deleteButton {
    @include mixins.button(true, true, #ff2828);

    margin: 0 auto;
  }
}
