@use "../mixins";
@use "../screens";
@use "../typography";
@use "../variables";

.content {
    padding: 3rem;
  @include screens.laptop {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .title {
    color : variables.$pink;
    @include typography.heading1;
    margin: 10px;
    @include screens.laptop {
      margin: 10px 20px;
    }
  }

  .subtitle {
    @include typography.heading2;
    margin: 10px;
    @include screens.laptop {
      margin: 10px 20px;
    }
  }

  .text {
        margin: 10px;
        @include typography.paragraph;
        font-size: 16px;
        @include screens.laptop {
          margin: 20px;
        }
    }

  .list{
    display: flex;
    flex-direction: column;
    align-items: center;
    @include screens.laptop {
      flex-direction: row;
    }
  }

  .item {
    font-size: 24px;
    margin: 10px;
    @include screens.laptop {
      margin: 10px 20px;
    }
    width: fit-content;
  }
  .retour {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 20px 0px;
    font-size: 16px;
    @include screens.laptop {
      margin: 10px 20px;
    }
  }
}

.image{
  height: 40vw;
  object-fit: cover;
  @include screens.laptop {
    height: 30vw;
  }
}

.link {
  transition:0.5s;

  &:hover {
    color:rgba(white, 0.5);
  }
}


