@use "../mixins";
@use "../screens";
@use "../typography";
@use "../variables";

.wrapper {
  @include mixins.section(true);
}

.title {
  @include typography.heading1;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;

  .label {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .input {
      @include mixins.input;
    }
  }

  .checkLabel {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 1rem auto 0;

    .bold {
      color: variables.$pink;
    }
  }

  .button {
    @include mixins.button;

    margin: 1rem auto 0;
  }
}
