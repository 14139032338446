@use "../../../styles/mixins";
@use "../../../styles/screens";
@use "../../../styles/typography";
@use "../../../styles/variables";

.wrapper {
  background-color: variables.$dark;
  color: white;
  position: relative;
}

.main {
  display: flex;
  flex-direction: column;
  position: relative;

  @include screens.laptop {
    padding-bottom: 8rem;
  }
}
