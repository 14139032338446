@use "../../../styles/mixins";
@use "../../../styles/screens";
@use "../../../styles/typography";
@use "../../../styles/variables";
@use "sass:color";

.planningContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @include screens.laptop {
    align-items: center;
  }

  .weekContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 2rem;

    .previous,
    .next {
      @include mixins.icon-button;
    }

    .previous {
      transform: rotate(180deg);
    }
  }

  .planning {
    width: 700vw;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    border-top: variables.$border;
    border-bottom: variables.$border;
    position: relative;

    @include screens.laptop {
      width: 100%;
    }

    .day {
      background-color: rgba(white, 0.05);

      @include screens.laptop {
        &:nth-of-type(2n + 1) {
          background-color: rgba(white, 0.1);
        }
      }

      &.today .header .date {
        color: color.adjust(variables.$pink, $lightness: 10%);
      }

      .header {
        padding: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: variables.$border;

        @include screens.laptop {
          justify-content: center;
        }

        .date {
          @include typography.heading2;

          text-align: center;
          white-space: nowrap;

          @include screens.laptop {
            @include typography.paragraph-bold;
          }
        }

        .previous,
        .next {
          @include mixins.icon-button;
        }

        .previous {
          transform: rotate(180deg);
        }
      }

      .list {
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        min-height: 32rem;

        @include screens.laptop {
          padding: 0.5rem;
          gap: 0.5rem;
        }
      }
    }

    .text {
      text-align: center;
      margin: auto;
      max-width: 80%;
    }
  }

  &.light {
    .weekContainer .previous,
    .weekContainer .next,
    .planning .previous,
    .planning .next {
      @include mixins.icon-button(true);
    }

    .planning .day {
      background-color: transparent;
      border-right: 1px solid rgba(variables.$dark, 0.1);

      &:nth-of-type(7) {
        border: none;
      }
    }
  }

  .button {
    @include mixins.button(true, true);

    margin: 2rem auto 0;
  }
}

.popup {
  @include mixins.popup;

  &.light {
    @include mixins.popup(true);
  }

  .title {
    @include typography.heading3;

    text-align: center;
    margin: 0 auto 1rem;
  }

  .text {
    .bold {
      @include typography.paragraph-bold;

      margin-left: 1rem;
    }
  }

  .done {
    font-weight: bold;
    margin: 1rem auto 0;
  }

  .button {
    @include mixins.button;

    margin: 1rem auto 0;
  }
}
