@use "../../../styles/mixins";
@use "../../../styles/screens";
@use "../../../styles/typography";
@use "../../../styles/variables";

.footer {
  padding: 3rem 1.5rem;
  background-color: variables.$dark;
  border-top: variables.$border;
  position: relative;
  z-index: 1;

  .figure {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    & a {
      margin-right: 50px;
    }
  }

  .figcaption {
    display: none;
  }

  .figcaptionTitle {
    @include typography.paragraph-bold;

    font-size: 2rem;
    margin-bottom: 10px;
  }

  .logo {
    margin-right: 30px;
  }

  @include screens.laptop {
    .figure {
      justify-content: center;
    }

    .figcaption {
      display: block;
    }
  }

  .linkContainer {
    margin-top: 20px;
    border-top: white solid 1px;

    .linkTitle {
      @include typography.paragraph-bold;

      margin-top: 10px;
    }

    .link,
    .text {
      margin-top: 1em;
    }

    .link {
      transition: color 0.2s ease-in-out;

      &:hover {
        color: rgba(white, 0.5);
      }
    }
  }

  @include screens.laptop {
    .listFooter {
      display: flex;
      justify-content: center;
    }

    .linkContainer {
      border-top: none;
      margin: 70px;
    }

    .figcaption {
      display: block;
    }
  }

  .networkContainer {
    border-top: white solid 1px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    .network {
      margin: 5px;
      margin-top: 10px;
    }
  }

  @include screens.laptop {
    .networkContainer {
      border-top: none;
      align-items: flex-start;
      margin: 70px;
    }
  }
}
