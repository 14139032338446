@use "../mixins";
@use "../screens";
@use "../typography";
@use "../variables";

.wrapper {
  @include mixins.section;
}

.hero {
  @include typography.heading1;
}

.subhero {
  @include typography.heading2;

  color: variables.$pink;
}

.title {
  @include typography.heading3;

  margin: 3rem 0 1.5rem;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .link {
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }
}
