@use "screens";
@use "variables";

@font-face {
  font-family: Raleway;
  src: url("../fonts/Raleway-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Raleway;
  src: url("../fonts/Raleway-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: Raleway;
  src: url("../fonts/Raleway-SemiBold.ttf");
  font-weight: 600;
}

body {
  font-family: Raleway, sans-serif;
  font-size: 0.875rem;

  @include screens.laptop {
    font-size: 1rem;
  }
}

.Wellness {
  color : variables.$wellness;
}

.Biking {
  color : variables.$biking;
}

.Cross-training {
  color : variables.$cross;
}
