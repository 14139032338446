@use "screens";

@mixin heading1 {
  font-weight: 500;
  font-size: 3rem;

  @include screens.laptop {
    font-size: 4rem;
  }
}

@mixin heading2 {
  font-weight: 500;
  font-size: 1.5rem;

  @include screens.laptop {
    font-size: 2.5rem;
  }
}

@mixin heading3 {
  font-weight: 500;
  font-size: 1rem;

  @include screens.laptop {
    font-size: 2rem;
  }
}

@mixin paragraph {
  font-size: inherit;
  line-height: 150%;
}

@mixin paragraph-bold {
  @include paragraph;

  font-weight: 600;
}
