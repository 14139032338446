@use "../mixins";
@use "../screens";
@use "../typography";
@use "../variables";

.header {
  @include mixins.section;

  display: flex;
  flex-direction: column;
  margin-top: 0 !important;

  @include screens.laptop {
    flex-direction: row;
    height: calc(100vh - 5rem);
  }

  .textContainer {
    @include screens.laptop {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0;

      // .logoTitle,
      .hero,
      .subhero,
      .buttonContainer,
      .gratuite {
        margin: 0 4rem;
      }
    }

    // .logoTitle {
    //   width: 12rem;
    //   margin: 0 auto;

    //   @include screens.laptop {
    //     width: 16rem;
    //   }
    // }

    .hero {
      @include typography.heading1;

      @include screens.laptop {
        font-size: 6rem;
      }
    }

    .subhero {
      @include typography.heading1;

      color: variables.$pink;
      max-width: 35rem;
      margin-top: 1.5rem;
    }

    .gratuite {
      font-size: 1.5em;
      margin-top: 1rem;
    }

    .buttonContainer {
      display: flex;
      gap: 1rem;
      margin-top: 2rem;

      @include screens.laptop {
        margin-top: 4rem;
      }

      .button:nth-child(1) {
        @include mixins.button;
      }

      .button:nth-child(2) {
        @include mixins.button(false);
      }
    }
  }

  .imageContainer {
    position: relative;
    margin-top: 2rem;

    @include screens.laptop {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0;
    }
  }

  .image {
    width: 100%;
    border-radius: 1rem;
    overflow: hidden;
    position: relative;
    margin-bottom: 8rem;

    @include screens.laptop {
      margin-bottom: 16rem;
    }

    .gradient {
      position: absolute;
      inset: 0;
      background-image: linear-gradient(transparent, rgba(variables.$dark, 0.5));
    }
  }

  .logo {
    position: absolute;
    width: 60%;
    top: 45%;
    left: 50%;
    transform: translateX(-50%);

    @include screens.laptop {
      width: 50%;
    }
  }
}

.section1 {
  @include mixins.section;

  .title {
    @include typography.heading1;
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 3rem 0;

    @include screens.laptop {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      margin: 4rem 0;
      gap: 4rem;
    }

    .item {
      display: flex;
      flex-direction: column;

      .icon {
        max-height: 3rem;
      }

      .smallTitle {
        @include typography.heading2;

        text-align: center;
        margin: 1rem 0;
      }

      .description {
        @include typography.paragraph;

        text-align: center;
      }
    }
  }

  .button {
    @include mixins.button(false);

    margin: 0 auto;
  }
}
.section2 {
  @include screens.laptop {
    margin-top: 8rem;
  }
}

.section3 {
  @include mixins.section;

  display: flex;
  flex-direction: column;

  .title {
    @include typography.heading2;

    .pink {
      color: variables.$pink;
    }
  }

  .bestavenContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .image {
      width: min(100%, 32rem);
      margin-top: 4rem;
      border-radius: 0.5rem;

      @include screens.laptop {
        margin-top: 6rem;
      }
    }

    .logo {
      position: absolute;
      width: min(80%, 24rem);
      top: 0;
    }
  }

  .video {
    width: min(100%, 55rem);
    aspect-ratio: 16 / 9;
    margin-top: 1.5rem;
    background-color: #000;
    border-radius: 0.5rem;
    overflow: hidden;

    @include screens.laptop {
      margin: 4rem auto 0;
    }
  }

  .youtube {
    width: min(100%, 55rem);
    aspect-ratio: 16 / 9;
  }

  .link {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    @include screens.mobile {
      img {
        width: 40%;
      }
    }
    
    @include screens.laptop {
      margin-top: 10px;
    }
  }

  .spivi {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    gap: 16px;
    font-size: 16px;
    margin-top: 16px;
  }

  .explicationSpivi {
    text-align: center;
    max-width: 48rem;
  }
}

.pink {
  color: variables.$pink;
}