@use "../../../styles/mixins";
@use "../../../styles/screens";
@use "../../../styles/typography";
@use "../../../styles/variables";

.navbar {
  display: flex;
  justify-content: center;
  padding: 0 1.5rem;
  background-color: variables.$dark;
  border-bottom: variables.$border;
  position: sticky;
  inset: 0 0 auto 0;
  z-index: 999;

  .wrapper {
    padding: 1rem 0;
    display: flex;
    justify-content: space-between;
    width: min(100%, 80rem);

    @include screens.laptop {
      padding: 2rem 0;
    }

    .logo {
      width: 2.5rem;
    }

    .linkContainer {
      display: flex;
      align-items: center;
      gap: 4rem;

      .link {
        a {
          transition: color 0.2s ease-in-out;
        }

        a:hover {
          color: rgba(white, 0.5);
        }

        &.selected a {
          color: variables.$pink !important;
        }
      }
    }

    .button {
      @include typography.paragraph-bold;

      display: flex;
      align-items: center;
      gap: 1.5rem;

      .arrow {
        transition: 0.2s ease-in-out;
      }

      &:hover .arrow {
        transform: translateX(0.25rem);
      }
    }
  }
}

.backdrop {
  position: fixed;
  inset: 0;
  pointer-events: none;
  z-index: 999;
  transition: 0.3s ease-in-out;

  &.active {
    background-color: rgba(variables.$dark, 0.5);
    pointer-events: all;
  }
}

.menu {
  position: fixed;
  top: 0;
  left: 100%;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  background-color: variables.$dark;
  z-index: 999;
  transition: left 0.3s ease-out;

  &.open {
    left: 0;
  }

  @include screens.laptop {
    width: 40rem;
    left: 100%;
    border-left: variables.$border;

    &.open {
      left: calc(100% - 40rem);
    }
  }

  .header {
    padding: 3rem 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .close {
      position: absolute;
      inset: 1.5rem 1.5rem auto auto;

      @include screens.laptop {
        inset: 2rem auto auto 2rem;
      }
    }

    .signInButton {
      @include mixins.button;
    }

    .signUpButton {
      @include mixins.button(false);
    }

    .text {
      margin: 2rem 0 1rem;
    }
  }

  .separator {
    width: 100%;
    border: none;
    border-bottom: variables.$border;
  }

  .main {
    padding: 3rem 1.5rem;
    display: flex;
    flex-direction: column;

    .title {
      display: flex;
      flex-direction: column;
      text-align: center;
      font-size: inherit;
      margin: 0 auto;

      .hero {
        @include typography.heading2;

        margin-bottom: 0.5rem;
      }

      .subhero {
        @include typography.paragraph;
      }
    }

    .linkContainer {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      margin: 3rem 0;
    }

    .button {
      @include mixins.button;

      margin: 0 auto;

      @include screens.laptop {
        margin-top: 2rem;
      }

      img {
        filter: invert(1);
      }
    }
  }
}
