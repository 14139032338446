/* stylelint-disable property-no-vendor-prefix */
@use "screens";
@use "typography";
@use "variables";

@mixin button($fill: true, $dark: false, $color: variables.$dark) {
  @include typography.paragraph-bold;

  padding: 1.5em 2em;
  border-radius: 99rem;
  width: fit-content;
  display: flex;
  align-items: center;
  text-align: center;
  gap: 0.75em;
  font-weight: 600;
  border: 2px solid transparent;
  transition: 0.2s ease-in-out;

  @if $dark == true {
    background-color: $color;
    color: white;

    &:hover {
      border-color: $color;
      background-color: white;
      color: $color;
    }
  } @else {
    border-color: white;

    @if $fill == true {
      background-color: white;
      color: $color;

      &:hover {
        border-color: white;
        background-color: transparent;
        color: white;

        img {
          filter: invert(0);
        }
      }
    } @else {
      &:hover {
        background-color: white;
        color: $color;
      }
    }
  }

  img {
    width: 1.5em;
    transition: filter 0.2s ease-in-out;
  }
}

@mixin gradient-text {
  background: linear-gradient(to right, variables.$pink, #a366b4 30%, #ff7b5f 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin section($small: false) {
  width: 100%;

  @if $small {
    max-width: 40rem;
  } @else {
    max-width: 80rem;
  }

  margin: 0 auto;
  padding: 3rem 1.5rem;

  @include screens.laptop {
    padding: 0;
    margin-top: 8rem;
  }
}

@mixin icon-button($dark: false) {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: rgba(white, 0.1);
  }

  &:active {
    background-color: rgba(white, 0.2);
    transition: none;
  }

  img {
    height: 1rem;
  }

  @if $dark == true {
    &:hover {
      background-color: rgba(variables.$dark, 0.05);
    }

    &:active {
      background-color: rgba(variables.$dark, 0.1);
    }

    img {
      filter: invert(1);
    }
  }
}

@mixin input {
  padding: 1em 1.5em;
  background-color: white;
  border-radius: 1rem;
  color: variables.$dark;
}

@mixin back-input {
  padding: 1em 1.5em;
  border-radius: 1rem;
  border: 1px solid rgba(variables.$dark, 0.2);
}

@mixin popup($light: false) {
  padding: 2rem;
  border-radius: 0.5rem;
  border: variables.$border;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: min(calc(100% - 3rem), 40rem);
  max-height: 90vh;
  overflow-y: auto;
  transform: scale(0.8) translateY(-1rem);
  transition: transform 0.1s ease-in, opacity 0.1s ease-in;

  @include screens.laptop {
    padding: 3rem;
  }

  @if $light == true {
    background-color: white;
    color: variables.$dark;

    .cross {
      filter: invert(1);
    }
  } @else {
    background-color: variables.$dark;
    color: white;
  }

  &:global(.open) {
    transform: none;
    transition: transform 0.3s cubic-bezier(0, 0.5, 0.5, 1.5), opacity 0.3s cubic-bezier(0, 0.5, 0.5, 1.5);
  }

  .cross {
    position: absolute;
    inset: 1rem 1rem auto auto;
  }
}
