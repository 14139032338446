@use "../mixins";
@use "../screens";
@use "../typography";
@use "../variables";


.content {
    margin: 64px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    @include screens.laptop {
        margin: 64px 0 0 0;
    }
}

.text {
    color: variables.$pink;
    font-weight: 500;
    font-size: 32px;
}

.button {
    @include mixins.button(true, true);
}

.anim {
    height: 200px;
    @include screens.laptop {
        height: 300px;
    }
}