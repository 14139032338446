@use "../../../styles/mixins";
@use "../../../styles/screens";
@use "../../../styles/typography";
@use "../../../styles/variables";

.lineContainer {
  position: absolute;
  inset: 0;
  text-align: center;
  overflow: hidden;

  svg {
    display: inline-block;

    @include screens.laptop {
      height: 100%;
    }
  }

  .mask {
    content: "";
    position: fixed;
    inset: 50% 0 0 0;
    background-color: variables.$dark;
    transition: inset 0.75s ease-in-out;

    &.top {
      inset: 0;
    }

    &.bottom {
      inset: 100% 0 0 0;
    }
  }
}
