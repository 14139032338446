@use "../../../styles/mixins";
@use "../../../styles/screens";
@use "../../../styles/typography";
@use "../../../styles/variables";

.cross {
  position: absolute;
  inset: 1rem 1rem auto auto;
}

.title {
  @include typography.heading3;

  text-align: center;
  margin: 0 auto 1rem;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .label {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .input {
      @include mixins.back-input;
    }
  }

  .labelCheckbox {
    display: flex;
    align-items: center;
    gap: 0.5em;
  }

  .separator {
    border: none;
    border-top: 1px solid variables.$dark;
    margin: 1rem 25%;
  }

  .button {
    @include mixins.button(true, true);

    margin: 1rem auto 0;
  }

  .wrapperButton {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;

    @include screens.laptop {
      flex-direction: row;
    }
  }

  .smallButton {
    @include mixins.button(true, true, #ff2828);

    padding: 1em 1.5em;
  }
}

.smallTitle {
  @include typography.heading3;

  margin: 2rem 0 1rem;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .item {
    display: flex;
    justify-content: space-between;

    .button {
      opacity: 0.5;
      transition: opacity 0.2s ease-in-out;

      &:hover {
        opacity: 1;
      }
    }
  }
}
