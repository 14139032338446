@use "../mixins";
@use "../screens";
@use "../typography";
@use "../variables";

.lien {
  @include typography.paragraph-bold;

  display: flex;
  align-items: center;
  gap: 1.5rem;

  .image {
    transition: 0.2s ease-in-out;
    width: 2%;
    @include screens.mobile {
      width: 5%;
      margin-left: 10px;
    }
  }

  &:hover .image {
    transform: translateX(-0.25rem);
    @include screens.mobile {
      transform: none;
    }
  }
}

.titre {
  @include typography.heading1;
  margin: 20px;
}

.form {
  margin: 50px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1024px) {
    margin: 10px;
  }
}

.champ {
  @include mixins.back-input;
  margin: 20px 0 20px 0;
}

.nomComplet {
  display: flex;
  justify-content: space-between;
  & label {
    width: 45%;
    & input {
      width:100%;
    }
  }
}

.naissanceTelephone {
  display: flex;
  justify-content: space-between;
  & label {
    width: 45%;
    & input {
      width:100%;
    }
  }
}

.adresse {
  display:flex;
  justify-content:space-between;
  & label {
    width:100%;
    & input {
      width:100%;
    }
  }
}

.email {
  display:flex;
  justify-content:space-between;
  & label {
    width:100%;
    & input {
      width:45%;
      @media (max-width: 480px) {
        width:100%;
      }
    }
  }
}

.credits {
  display:flex;
  justify-content:space-between;
  & label {
    width:45%;
    & input {
      width:100%;
    }
  }
}

.button {
  align-self:center;
  @include mixins.button(true);
  margin: 20px 20px 20px 20px;
  padding: 15px;
  border-color: black;
  @include screens.mobile {
    padding: 10px;
  }
  &:hover {
    color: white;
    background-color: black;
    border-color: white;
  }
}
