@use "../../../styles/mixins";
@use "../../../styles/screens";
@use "../../../styles/typography";
@use "../../../styles/variables";

.seance {
  background-color: rgba(white, 0.05);
  border-radius: 0.5rem;

  .header {
    padding: 1.5rem 2rem;
    background-color: rgba(white, 0.05);
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: rgba(white, 0.075);
    }

    &:active {
      background-color: rgba(white, 0.1);
    }

    .color {
      width: 0.5rem;
      aspect-ratio: 1 / 1;
      border-radius: 50%;

      &:global(.Wellness) {
        background-color: variables.$wellness;
      }

      &:global(.Cross-training) {
        background-color: variables.$cross;
      }

      &:global(.Biking) {
        background-color: variables.$biking;
      }
    }

    .title {
      @include typography.paragraph-bold;

      margin: 0 auto 0 1rem;
    }

    .arrow {
      transform: rotate(90deg);
      transition: transform 0.3s ease-in-out;

      img {
        width: 0.75rem;
      }
    }
  }

  .contentWrapper {
    height: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .bold {
      font-weight: bold;
    }
  }

  .button {
    @include mixins.button(true, false);

    margin: 1rem auto 0;
  }

  &.open {
    .header .arrow {
      transform: rotate(-90deg);
    }

    .contentWrapper {
      height: 100%;
      padding: 1.5rem 2rem;
    }
  }
}
