@use "../mixins";
@use "../screens";
@use "../typography";
@use "../variables";


.section {
  
  @include screens.laptop {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .title {
    @include typography.heading1;
    margin: 10px;
    padding: 0.5rem;
    @include screens.laptop {
      flex-direction: row;
      margin: 2rem 5rem;
    }
  }

  .slogan {
    @include typography.heading2;
    margin: 10px;
    padding: 0.5rem;
    .pink {
      color: variables.$pink;
    }
    @include screens.laptop {
      flex-direction: row;
      margin: 1rem 5rem;
    }
  }

  .jaune {
    color: variables.$biking;
  }
  .bleu {
    color: variables.$cross;
  }
  .vert {
    color: variables.$wellness;
  }
}

.figure {
    height: 30vh;
    position: relative;

    @include screens.laptop {
      height: 50vh;
    }

    .image {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .gradient {
      position: absolute;
      inset: 0;
      background: linear-gradient(to right, transparent, rgba(variables.$dark, 0.8));

      @include screens.laptop {
        background: linear-gradient(transparent, rgba(variables.$dark, 0.8));
      }
    }

    .content {
      position: absolute;
      inset: 0 1.5rem 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: end;

      @include screens.laptop {
        inset: 0;
        align-items: center;
        justify-content: center;
      }

      .logo {
        width: 8rem;

        @include screens.laptop {
          width: min(50%, 20rem);
          position: absolute;
          bottom: 0;
          right: 5rem;
        }
      }
    }
  }

.list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 10px;
  padding: 0.5rem;
  @include screens.laptop {
    flex-direction: row;
    margin: 3rem 5rem;
    gap: 64px;
  }
  .item {
    display: flex;
    flex-direction: column;
    gap: 8px;
    line-height: 20px;
    text-align: justify;
    
    .subtitle {
      font-weight: 600;
      align-self: center;
    }
  }
}

.button {
  margin: 3rem auto ;
  @include mixins.button;
}

.bottomImage {
  width: min(80%, 32rem);
  margin: 2rem auto 0;
  border-radius: 1rem;
}
