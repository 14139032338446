@use "../../../styles/mixins";
@use "../../../styles/screens";
@use "../../../styles/typography";
@use "../../../styles/variables";

.wrapper {
  @include screens.laptop {
    display: flex;
  }
}

.mobileNavbar {
  position: sticky;
  inset: 0 0 auto 0;
  background-color: white;
  box-shadow: 0 1rem 1rem rgba(variables.$dark, 0.05);
  padding: 1.5rem;
  display: flex;
  align-items: center;
  z-index: 99;
  justify-content: space-between;

  .button {
    height: 1.5rem;

    .icon {
      filter: invert(1);
    }
  }
}

.menu {
  position: fixed;
  inset: 0;
  transform: translateX(100vw);
  background-color: variables.$light-grey;
  z-index: 99;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  transition: transform 0.2s ease-in-out;

  @include screens.laptop {
    position: sticky;
    height: fit-content;
    min-height: 100vh;
    flex: 0 0 auto;
    inset: unset;
    top: 0;
    transform: none;
    padding: 4rem 2rem;

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      background-color: variables.$light-grey;
      box-shadow: 1rem 0 1rem rgba(variables.$dark, 0);
      transition: inset 0.3s ease-in-out;
    }

    &:hover {
      &::before {
        inset: 0 -16rem 0 0;
        box-shadow: 1rem 0 1rem rgba(variables.$dark, 0.05);
      }

      .wrapped {
        clip-path: inset(0 0 -1rem 0);
      }

      .bottomLink {
        clip-path: inset(0);
      }
    }
  }

  @include screens.desktop {
    padding: 4rem;
  }

  &.open {
    transform: none;
  }

  .wrapped {
    @include screens.laptop {
      position: absolute;
      left: 4rem;
      white-space: nowrap;
      clip-path: inset(0 100% 0 0);
      transition: clip-path 0.3s ease-in-out;
    }
  }

  .header {
    display: flex;
    align-items: center;
    position: relative;

    .hero {
      @include typography.heading2;

      margin: 0 auto 0 1rem;

      @include screens.laptop {
        @include typography.heading3;

        margin: 0;
      }
    }

    .button {
      height: 1.5rem;

      .icon {
        filter: invert(1);
      }
    }
  }

  .connected {
    display: flex;
    gap: 0.5rem;
    margin: 2rem 0;
    position: relative;

    @include screens.laptop {
      margin: 4rem 0;
      justify-content: center;
    }

    .indicator {
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 50%;
      background-color: #00e55c;
    }

    .text {
      @include typography.paragraph;

      display: flex;
      flex-direction: column;
      line-height: 1;

      .bigger {
        @include typography.heading3;

        @include screens.laptop {
          font-size: 1.4rem;
          margin-top: 0.25rem;
        }
      }
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @include screens.laptop {
      gap: 3rem;
    }

    .link {
      display: flex;
      align-items: center;
      gap: 1rem;
      position: relative;

      @include screens.laptop {
        justify-content: center;
      }
    }
  }

  // .bottomLink {
  //   margin: auto auto 0;
  //   padding: 1em;
  //   border-radius: 0.5rem;
  //   transition: background-color 0.2s ease-in-out, clip-path 0.3s ease-in-out;

  //   &:hover {
  //     background-color: rgba(variables.$dark, 0.05);
  //   }

  //   @include screens.laptop {
  //     position: absolute;
  //     bottom: 4rem;
  //     white-space: nowrap;
  //     clip-path: inset(0 100% 0 0);
  //   }
  // }
}

.main {
  width: 100%;
  color: variables.$dark;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 2rem 0 4rem;

  @include screens.laptop {
    padding: 4rem 2rem;
  }

  @include screens.desktop {
    padding: 4rem;
  }

  h2 {
    @include typography.heading1;

    margin: 0 1.5rem;

    @include screens.laptop {
      margin: 0;
    }
  }
}
