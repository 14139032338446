@use "../mixins";
@use "../screens";
@use "../typography";
@use "../variables";

.wrapper {
  @include mixins.section(true);
}

.title {
  @include typography.heading1;
}

.subtitle {
  @include typography.heading2;

  margin-top: 4rem;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;

  .horizontal {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @include screens.laptop {
      flex-direction: row;
    }

    .label {
      width: 100%;
    }
  }

  .label {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .input {
      @include mixins.input;
    }
  }

  .button {
    @include mixins.button;

    margin: 1rem auto 0;
  }
}

.deleteButton {
  width: 100%;
  text-align: center;
  margin-top: 4rem;

  &:hover {
    text-decoration: underline;
  }
}
