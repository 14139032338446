@use "../../../styles/mixins";
@use "../../../styles/screens";
@use "../../../styles/typography";
@use "../../../styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    @include typography.heading2;

    margin-bottom: 2rem;
    text-align: center;
  }

  .credits {
    @include typography.heading2;
  }

  .add {
    @include typography.paragraph-bold;
  }

  .button {
    @include mixins.button;

    margin: 2rem 0 1rem;
  }

  .emptyButton {
    @include mixins.button(false);
  }

  .logout {
    margin-top: 2rem;

    &:hover {
      text-decoration: underline;
    }
  }
}
