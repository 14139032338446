@use "../mixins";
@use "../screens";
@use "../typography";
@use "../variables";

.header {
  @include screens.laptop {
    display: flex;
    align-items: center;
    padding: 3rem;
  }

  .title {
    @include typography.heading1;
    margin: 10px;
  }
  .subtitle {
    @include typography.heading2;
    color : white;
    margin: 10px;
    margin-bottom: 30px;
    .pink {
      color : variables.$pink;
    }
    @include screens.laptop {
      width: 75%;
    }
  }
}

.image{
  height: 40vw;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 24px 32px;
  &:nth-of-type(2n) {
    align-items: flex-end;
  }
  @include screens.laptop {
    height: 30vw;
    padding: 64px 128px;
  }
  .name {
    @include typography.heading2;
    margin-bottom: 8px;
    @include screens.laptop {
      margin-bottom: 24px;
    }
  }
  .button {
    @include mixins.button;
  }
}
