@use "sass:color";

/* Colors */
$pink: #e62569;
$dark: #141016;
$biking: #ebd12e;
$cross: #0093a5;
$wellness: #798a36;
$light-grey: #eee;

/* Border */
$border: 1px solid rgba(white, 0.4);
