@use "../mixins";
@use "../screens";
@use "../typography";
@use "../variables";

.titre {
    @include typography.heading1;
    padding: 20px;
}

.texte {
    padding-left: 20px;
    margin-top: 5px;
    line-height: 30px;
}

.soustitre {
    padding-left: 20px;
    @include typography.heading3;
    color: variables.$pink;
    margin-bottom: 10px;
    margin-top: 20px;
    font-size: 1.5rem;
}

.dernier {
    margin-bottom: 10px;
}
  


