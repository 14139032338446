@use "../../../styles/mixins";
@use "../../../styles/screens";
@use "../../../styles/typography";
@use "../../../styles/variables";

.wrapper {
  position: relative;
}

.input {
  @include mixins.back-input;

  width: 100%;
}

.popup {
  position: absolute;
  inset: auto 0 calc(100% + 1rem) 0;
  background-color: white;
  border-radius: 0.5rem;
  border: 1px solid variables.$light-grey;
  box-shadow: 0 1rem 1rem variables.$light-grey;
  transition: opacity 0.2s ease-in-out;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  .title {
    @include typography.paragraph-bold;

    padding: 1em;
  }

  .cross {
    position: absolute;
    inset: 1rem 1rem auto auto;

    img {
      filter: invert(1);
    }
  }

  .list {
    height: fit-content;
    max-height: 16rem;
    overflow: auto;
  }

  .button {
    padding: 1em;
    width: 100%;
    text-align: left;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: variables.$light-grey;
    }
  }
}
